// import {sassTrue} from "sass";

const ModalModule = {
  namespaced: true,
  state: {
    ModelOpen: false,
    ModelViewOpen: false,
    isOpenSecondaryModal: false,
    isOpenInfoModal: false,
    secondaryModalValue:'',
    params: {},
  },
  mutations: {
    SET_MODEL_OPEN(state, payload) {
      if(payload.open && !state.ModelOpen) state.ModelOpen = payload.open;
      else if(payload.open && state.ModelOpen) state.isOpenSecondaryModal = payload.open;

      if(!payload.open && state.isOpenSecondaryModal) {
        state.isOpenSecondaryModal = payload.open;
      }
      else state.ModelOpen = payload.open;
      // state.ModelOpen = payload.open;
      state.params = payload.params;
    },
    SET_SECONDARY_MODAL_VALUE(state, id){
      state.secondaryModalValue = id;
    },
    SET_MODEL_OPEN_VIEW(state, payload) {
      state.ModelViewOpen = payload.open;
      state.params = payload.params;
    },
    SET_MODEL_OPEN_INFO(state, payload) {
      state.isOpenInfoModal = payload.open;
    },
  },
  actions: {
    closeModel({ commit }) {
      commit('SET_MODEL_OPEN', { open: false, params: {} });
    },
    closeViewModel({ commit }) {
      commit('SET_MODEL_OPEN_VIEW', { open: false, params: {} });
    },
    closeInfoModel({ commit }) {
      commit('SET_MODEL_OPEN_INFO', { open: false});
    },
    openModel({ commit }, params = {}) {
      commit('SET_MODEL_OPEN', { open: true, params });
    },
    openViewModel({ commit }, params = {}) {
      commit('SET_MODEL_OPEN_VIEW', { open: true, params });
    },
    openInfoModel({ commit }) {
      commit('SET_MODEL_OPEN_INFO', { open: true });
    },
    setSecondaryModalValue({ commit }, id ) {
      commit('SET_SECONDARY_MODAL_VALUE', id);
    },
    // editModel({ commit }, id) {
    //   commit('SET_MODEL_OPEN', { open: true, id });
    // },
  },
  getters: {},
};
export default ModalModule;
