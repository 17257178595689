import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { Mutations, Actions } from '@/store/enums/StoreEnums';
import { Actions as AdminActions } from '@/modules/admin/store/enums/AuthAdminEnums';
import adminRouter from '@/modules/admin/router';

const routes: Array<RouteRecordRaw> = [
  ...adminRouter,
  {
    path: '/',
    redirect: {name:'dashboard'},
    component: () => import('@/layout/Layout.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/modules/admin/views/DashboardAdmin.vue'),
      },
    ],
  },
  // {
  //   path: "/",
  //   component: () => import("@/components/page-layouts/Auth.vue"),
  //   children: [
  //     {
  //       path: "/sign-in",
  //       name: "sign-in",
  //       component: () =>
  //         import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
  //     },
  //     {
  //       path: "/sign-up",
  //       name: "sign-up",
  //       component: () =>
  //         import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
  //     },
  //     {
  //       path: "/password-reset",
  //       name: "password-reset",
  //       component: () =>
  //         import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
  //     },
  //   ],
  // },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () =>
      import('@/modules/common/views/authentication/Error404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (store.getters.isAdminAuthenticated) {
    store.dispatch(AdminActions.ADMIN_VERIFY_AUTH);
  } else {
    store.dispatch(Actions.VERIFY_AUTH);
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
