
import AuthAdminModule from './modules/AuthAdminModule';
import CRUDModule from './modules/CRUDModule';
import AdminModulesModule from './modules/AdminModulesModule';
import PartnerModule from './modules/PartnerModule';
import VenderModule from './modules/VenderModule';
import ModalModule from './modules/ModalModule';
import DashboardModule from './modules/DashboardModule';

const adminStore = {
    AuthAdminModule,
    AdminModulesModule,
    ModalModule,
    PartnerModule,
    VenderModule,
    DashboardModule,
    CRUDModule
};

export default adminStore;
