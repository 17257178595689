import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ListModule from "@/store/modules/ListModule";
import MappingListModule from "@/store/modules/MappingListModule";
import MappingPropertyListModule from "@/store/modules/MappingPropertyListModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import adminStore from "@/modules/admin/store";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ListModule,
    MappingListModule,
    MappingPropertyListModule,
    ...adminStore,
  },
});

export default store;
