import axios from 'axios';

const MappingPropertyListModule = {
  namespaced: true,
  state: {
    list: {},
    cacheLists: {},
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list[payload.listName] = payload.list;
      state.cacheLists[payload.listName + JSON.stringify(payload.filters)] =
        payload.list;
    },
  },
  actions: {
    getListByName({ commit, state }, options) {
      let listName  = typeof options === 'object' ? options.name  : options;
      let listId    = typeof options === 'object' ? options.id  : options;
      let path      = 'core/mapping/';
      // console.log(22222);
      // console.log(options.filters);
      if (
        listName + JSON.stringify(options.filters ?? {}) in
        state.cacheLists
      )
        return;

      return new Promise((resolve, reject) => {
        axios
          .get(path + listName + '/' + listId , {
            params: {
                filters: options.filters ?? {},
            },
          })
          .then((res) => {
            // console.log(res);
            commit('SET_LIST', {
              listName,
              list: res.data.result,
            });
            
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    getListByName: (state) => (id) => {
      return state.DataTable.find((x) => x.id === id);
    },
  },
};
export default MappingPropertyListModule;
