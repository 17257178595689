import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    signIn: 'Sign in',
    toLinkerWebsite: 'To Linker System',
    email: 'Email',
    password: 'Password',
    packageManagement: 'Package Management',
    subscriptions: 'Subscriptions',
    subscriptionsPayment: 'Subscriptions Payment',
    short_description_ar: "Short Description Ar",
    short_description_en: "Short Description En",
    title_ar: "Title Ar",
    title_en: "Title En",
    coupons: 'Coupons',
    reports: 'Reports',
    Key: 'Key',
    filter: 'Filter',
    createNewPackage: 'Create New Package',
    updatePackage: 'Update Package',
    productCount: 'Product Count',
    mealsCount: 'Meals Count',
    product: 'Product',
    marketCount: 'Market Count',
    markets: 'Markets',
    MARKET: 'Market',
    RESTAURANT: 'Restaurant',
    marketsPackage: 'Markets Package',
    restaurantsPackage: 'Restaurants Package',
    restaurantsDashboard: 'Restaurants Statistics',
    subscriptionPeriod: 'Subscription',
    unlimited: 'Unlimited',
    MONTH: 'Month',
    YEAR: 'Year',
    LIFE_TIME: 'Life time',
    SR: 'SR',
    monthlySubscription: 'Monthly Subscription',
    yearlySubscription: 'Yearly Subscription',
    lifetimeSubscription: 'Lifetime Subscription',
    subscriptionType:'Subscription Type',
    packagePrice:'Package Price',
    packageNameInArabic:'Package Name In Arabic',
    packageNameInEnglish:'Package Name In English',
    shortDescriptionInArabic:'Short Description In Arabic',
    shortDescriptionInEnglish:'Short Description In English',
    detailedExplanationInArabic:'Detailed Explanation In Arabic',
    detailedExplanationInEnglish: 'Detailed Explanation In English',
    contents: "Contents",
    "Create content": "Create content",
    "update content": "update content",
    save: 'Save',
    cancel: 'Cancel',
    accountSettings: 'Account Settings',
    updateProfile: 'Update Profile',
    signOut: 'Sign Out',
    couponManagement: 'Coupon Management',
    createNewCoupon: 'Create New Coupon',
    filterOptions: 'Filter Options',
    numBranches: 'Number Branches',
    addedPrice: 'Added Price',
    numOrders: 'Number Orders',
    numSharedApps: 'Number Shared Apps',
    numBrands: 'Number Brands',
    apply: 'Apply',
    reset: 'Reset',
    monthNumber: 'Month Number',
    yearNumber: 'Year Number',
    pleaseWait: 'Please Wait',
    startDate: 'Start Date',
    endDate: 'End Date',
    couponCode: 'Coupon Code',
    userCount: 'User Count',
    count: 'Count',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    couponsNameInArabic: 'Coupon Name In Arabic',
    couponsNameInEnglish: 'Coupon Name In English',
    couponName: 'Coupon Name',
    forgotPassword: 'Forgot your password?',
    resetPassword: 'Reset Password',
    resetPasswordDesc: 'Please enter your email to reset your password',
    storeInformation: 'Store Information',
    fillInTheStore: 'Fill in the store information to continue',
    choosingThePackage: 'Choosing The Package',
    chooseThePackageToContinue: 'Choose the package to continue',
    thePaying: 'The Payment',
    confirmThePayment: 'Confirm the payment',
    createNewCoupons: 'Create New Coupons',
    updateCoupons: 'Update Coupons',
    couponType: 'Coupon Type',
    couponValue: 'Coupon Value',
    packages: 'Packages',
    PERCENTAGE: 'Percentage',
    FIXED: 'Fixed',
    fromDate: 'From Date',
    delete: 'Delete',
    edit: 'Edit',
    toDate: 'To Date',
    thePackage: 'Package',
    theCoupon: 'Coupon',
    active: 'Active',
    venders: 'Vendors',
    Restaurants: 'Restaurants',
    orderCount: 'Order Count',
    information: 'Information',
    payments: 'Payments',
    storeName: 'Store Name',
    companyName: 'Company Name',
    commercialRegistrationName: 'Commercial Registration Name',
    commercialRegistrationExpire: 'Commercial Registration Expire',
    phone: 'Phone Number',
    description: 'Description',
    vendorInformation:'Vendor Information',
    venderManagement: 'Vendor Management',
    createNewVender: 'Create New Vendor',
    searchVender: 'Search Vendor',
    status: 'Status',
    venderName: 'Vendor Name',
    sellerName: 'Seller Name',
    companyNname: 'Company Name',
    products: 'Products',
    package: 'Package',
    registerDate: 'Register Date',
    actions: 'Actions',
    per: 'Per',
    packageType:'Package Type',
    subscriberInformation:'Subscriber Information',
    paymentDetail:'Payment Detail',
    subscriptionDetail:'Subscription Detail',
    subscriptionAt:'Subscription At',
    processId:'Process Id',
    date:'Date',
    subscriptionEnds:'Subscription Ends',
    users:'Users',
    userManagement: 'User Management',
    createNewUser: 'Create New User',
    userName: 'User Name',
    userType: 'User Type',
    admin: 'Admin',
    inactive: 'Inactive',
    searchUser: 'Search User',
    website: 'Website',
    numberOfSubscribers: 'Number Of Subscribers',
    numberOfOrders: 'Number Of Orders',
    searchSubscription: 'Search Subscription',
    subscriptionManagement: 'Subscription Management',
    createNewSubscription: 'Create New Subscription',
    expiryDate: 'Expiry Date',
    amount: 'Amount',
    paymentMethod: 'Payment Method',
    notRegisteredYet: 'Not Registered Yet',
    deleteAccount: 'Delete Account',
    location: 'Location',
    view: 'View',
    APPLE_PAY: 'APPLE PAY',
    SUCCESS: 'Success',
    CASH: 'Cash',
    BANK_TRANSFER: 'Bank Transfer',
    CREDIT_CARD: 'Credit Card',
    CACHE: 'Cash',
    BANK: 'Transfer',
    USED_FOR_SUBSCRIPTION: 'Subscription',
    by: 'By',
    "Please select image": 'Please select image',
    username: 'Username',
    passwordConfirmation: 'Password Confirmation',
    avatar: 'Avatar',
    updateUser: 'Update User',
    roles: 'Roles',
    NoActivePackage: 'No Active Package',
    copyright: 'Copyright',
    aboutLinker: 'About Linker',
    contactUs: 'Contact Us',
    numberOfVenders: 'Number Of Vendors',
    numberOfRestaurants: 'Number Of Restaurants',
    numberOfProducts: 'Number Of Products',
    numberOfMeals: 'Number Of Meals',
    ordersValue: 'Orders Value',
    numberOfCouponsUsed: 'Number Of Coupons Used',
    numberOfPackages: 'Number Of Packages',
    byMonths: 'By Months',
    newSellers: 'New Sellers',
    newRestaurants: 'New Restaurants',
    newProducts: 'New Products',
    newMeals: 'New Meals',
    subscriptionValue: 'Subscription Value',
    bestSellers: 'Best Sellers',
    bestRestaurants: 'Best Restaurants',
    accordingToOrdersNumber: 'According To Orders Number',
    accordingToTypesOfPackages: 'According To Types Of Packages',
    orders: 'Orders',
    statistics: 'Statistics',
    discount: 'Discount',
    continue: 'Continue',
    close: 'Close',
    back: 'Back',
    subdomain: 'Subdomain',
    pleaseChoosePaymentMethod: 'Please choose a payment method',
    applePay: 'Apple Pay',
    creditCard: 'Credit Card',
    nameOnCard: 'Name On Card',
    cardNumber: 'Card Number',
    expirationDate: 'Expiration Date',
    addCouponCode: 'Please Add Coupon Code',
    value: 'Value',
    theDiscount: 'The Discount',
    total: 'Total',
    role :'User Type',
    basic :'primary user',
    branch :'Sub user',
    subject:"Subject",
    setting:"Setting",
    contact:"Contact Us",
    blogs:"Blogs",
    language:"Language",
    order:"Order",
    seo_link:"Seo Link",
    short_description:"Short Description",
    body:"Body",
    arabic:"Arabic",
    english:"English",
    "Create blog":"Create blog",
    "update blog":"update blog",
    title:"Title",
    message:"Message",
    "blogs Management":"blogs Management",
    "contacts Management":"contacts Management",
    "Contact Management":"Contact Management",
    "Please wait...": 'Please wait...',
    "Password updated successfully!": 'Password updated successfully!',
    "Try again!": 'Try again!',
    "No data found": "No data found",
    "user saved successfully!": "User Saved Successfully!",
    "Ok, got it!":"Ok, got it!",
    "Are you sure you want to delete?":"Are You Sure You Want To Delete?",
    "Yes, delete it!": "Yes",
    "No, cancel!": "No",
    "coupon saved successfully!":"Coupon saved successfully!",
    "Subscription package saved successfully!":"Subscription package saved successfully!",
    "Required Field":"Required Field",
    "token not valid.":"Token not valid.",
    "must be a valid email":"must be a valid email",
    "phone must be a valid phone number":"phone must be a valid phone number.",
    "Passwords must match":"Passwords must match",
    "must be integer":"must be integer",
    "must be grater than":"must be grater than",
    "must be less than":"must be less than",
    "Verification link sent successfully please check your email!":"Verification link sent successfully please check your email!",
    "Verification link already sent":"The verification link has already been sent. Please wait 5 minutes before requesting a new one.",
    "must be":"must be",
    "number":"number",
    "Number":"Number",
    "must be at least":"must be at least",
    "must be at most":"must be at most",
    "The password must be at least 8 characters.":"The password must be at least 8 characters.",
    "characters":"characters",
    "You cannot choose an earlier date": "You cannot choose an earlier date.",
    "This admin does not exist.": "This admin does not exist.",
    "This admin is not active.": "This admin is not active.",
    "Enter correct website!":"Enter correct website!",
    "Please enter website":"Please enter website",
    "email already taken":"Email already taken",
    "Allowed file types":"Allowed file types",
    "Allowed file types2":"Allowed file types: png, jpg, jpeg.",
    "Invalid credentials":"Invalid credentials",

    "Submit":"Submit",
    "invoice":"Invoice",
    "invoice number":"Invoice Number",
    "tax number":"Tax Number",
    "invoice date":"Invoice Date",
    "time":"Time",
    "duration":"Duration",
    "price":"Price",
    "net total":"Net Total",
    "tax":"Tax",
    "address":"Address",
    "The subscription is active until":"The subscription is active until",
    "paid":"Paid",
    "store Information":"Store Information",
    "print invoice":"Print Invoice",
    "company name":"Company Name",
    "package type":"Package Type",
    "market_id": "Market Id",
    "market_url": "Market URL",
    "gateway_type": "Gateway Type",
    "is_linked": "Linked Status",
    "linked": "Linked",
    NameInArabic: '{name} Name In Arabic',
    NameInEnglish: '{name} Name In English',
    "not_linked": "Not Linked",
    "categories": "Categories",
    "category":"Category",
    "category saved successfully!":"Category saved successfully!",
    "updateCategory": "Update category",
    "categoryManagement": "Category management",
    "categoryName": "Category name",
    "name ar": "Name Ar",
    "name en": "Name En",
    "createNewCategory": "Create new category",
    "property saved successfully!": "Property saved successfully",
    "createNewProperty": "Create new property",
    "updateProperty": "Update property",
    "properties": "Properties",
    "Partners":"Partners",
    "Partners Management":"Partners Management",
    "Create Partner":"Create Partner",
    "partner name":"Partner Name",
    "create New Partner":"create New Partner",
    "Update Partner":"Update Partner",
    "Company Name":"Company Name",
    "wallet balance":"wallet balance",
    "referral group":"Referral group",
    "referral groups":"Referral Groups",
    "ShareReferralCode":"Share Code",
    "incomes_form_referral":"Incomes Form Referral",
    "referral_code":"Referral Code",
    "PartnerIformation":"Partner Information",
    "referralSellers":"Referral Sellers",
    "full_name":"Full Name",
    "second_phone":"Second Phone",
    "referral_due_months" :"Referral Due Months",
    "referral_consumed_months":"Referral Consumed Months",
    "commercial_registration":"Commercial Registration",
    "tax_no":"Tax No",
    "referral_percentage":"Referral Percentage",
    "go-back":"Back",
    "attribute_type": "Attribute type",
    "linker_product_attribute": "Linker product attribute",
    "description ar":"Description Ar",
    "description en":"Description En",
    "propertyManagement": "Property management",
    "saved successfully!": "saved successfully!",
    "categoryMapping": "Category Mapping",
    "applications":"Applications",
    "main_category_id": "Main category ",
    "not mapped": "Not mapped",
    "mapped": "Mapped",
    "isMapped": "Category Mapping",
    "propertyMapping": "Property mapping",
    "referral code groups": "Referral groups",
    "referralCodeGroupsManagement": "Referral code groups management",
    "percentage": "Percentage",
    "numberOfMonths": "Number of months",
    "isDefaultForSellers": "Is default for sellers",
    "Yes": "Yes",
    "No": "No",
    "createNewReferralCodeGroups": 'Create group',
    "createPayment": 'Create Payment',
    "payment_no": 'Payment No.',
    "payment_amount": 'Payment Amount',
    "payment_date": 'Payment Date',
    "payment_file": 'Payment File',
    "ReferralCodeGroups": "Referral code groups",
    "isPropertyMapped": "Is property mapped",
    "mainProperty": "Main property",
    meal: 'Meal',

    "Password reset successfully!":"Password reset successfully!",
    "":"",

  },
  ar: {
    invoice:"الفاتورة",
    signIn: 'تسجيل الدخول',
    toLinkerWebsite: 'إلى نظام لنكر',
    email: 'البريد الالكتروني',
    password: 'كلمة المرور',
    packageManagement: 'إدارة الباقات',
    subscriptions: 'الاشتراكات',
    coupons: 'الكوبونات',
    reports: 'التقارير',
    filter: 'فلترة',
    createNewPackage: 'إنشاء باقة جديدة',
    restaurants: 'مطاعم',
    marketsPackage: 'باقات المتاجر',
    restaurantsPackage: 'باقات المطاعم',
    restaurantsDashboard: 'إحصائيات المطاعم',
    updatePackage: 'تعديل الباقة',
    productCount: 'عدد المنتجات',
    mealsCount: 'عدد الواجبات',
    product: 'منتج',
    marketCount: 'عدد الاسواق',
    orderCount: 'عدد الطلبات',
    markets: 'اسواق',
    subscriptionPeriod: 'مدة الاشتراك',
    unlimited: 'غير محدود',
    NameInArabic: 'اسم {name} بالعربي',
    NameInEnglish: 'اسم {name} بالانكليزي',
    MONTH: 'شهر',
    YEAR: 'سنة',
    LIFE_TIME: 'مرة واحدة',
    SR: 'ريال سعودي',
    monthlySubscription: 'اشتراك شهري',
    numBranches: 'عدد الفروع',
    addedPrice: 'قيمة المضافة',
    numOrders: 'قيمة محددة',
    numSharedApps: 'عدد التطبيقات',
    numBrands: 'عدد البراندات',
    yearlySubscription: 'اشتراك سنوي',
    lifetimeSubscription: 'اشتراك مفتوح',
    subscriptionType:'نوع الاشتراك',
    packagePrice:'سعر الاشتراك',
    packageNameInArabic:'اسم الباقة بالعربي',
    packageNameInEnglish:'اسم الباقة بالانكليزي',
    shortDescriptionInArabic:'شرح المختصر بالعربي',
    shortDescriptionInEnglish:'شرح المختصر بالانكليزي',
    detailedExplanationInArabic:'الشرح المفصل بالعربي',
    detailedExplanationInEnglish:'الشرح المفصل بالانكليزي',
    save:'حفظ',
    cancel:'الغاء',
    setting:"الإعدادات",
    contact:"طلبات التواصل",
    accountSettings: 'إعدادات الحساب',
    signOut: 'تسجيل الخروج',
    couponManagement: 'إدارة الكوبونات',
    createNewCoupon: 'إنشاء كوبون جديد',
    filterOptions: 'خيارات الفلترة',
    name: 'الاسم',
    apply: 'تطبيق',
    reset: 'إعادة تعيين',
    updateProfile: 'تحديث الملف الشخصي',
    monthNumber: 'عدد الاشهر',
    yearNumber: 'عدد السنين',
    pleaseWait: 'يرجى الانتظار',
    startDate: 'تاريخ البداية',
    endDate: 'تاريخ النهاية',
    couponCode: 'كود الكوبون',
    couponName: 'اسم الكوبون',
    userCount: 'عدد المستخدمين',
    createdAt: 'تاريخ الإنشاء',
    updatedAt: 'تاريخ التعديل',
    couponsNameInArabic: 'اسم الكوبون بالعربي',
    couponsNameInEnglish: 'اسم الكوبون بالانكليزي',
    forgotPassword: 'هل نسيت كلمة المرور؟',
    resetPassword: 'إعادة تعيين كلمة المرور',
    resetPasswordDesc: 'يرجى ادخال البريد الالكتروني لاعادة تعيين كلمة المرور',
    storeInformation: 'معلومات المتجر',
    fillInTheStore: 'إملأ معلومات المتجر للمتابعة',
    choosingThePackage: 'إختيار الباقة',
    chooseThePackageToContinue: 'قم باختيار الباقة للمتابعة',
    thePaying: 'الدفع',
    confirmThePayment: 'قم بتاكيد الدفع',
    createNewCoupons: 'إنشاء كوبون جديد',
    updateCoupons: 'تعديل الكوبون',
    couponType: 'نوع الكوبون',
    couponValue: 'قيمة الكوبون',
    packages: 'الباقات',
    PERCENTAGE: 'نسبة مئوية',
    FIXED: 'قيمة',
    fromDate: 'من تاريخ',
    toDate: 'الى تاريخ',
    delete: 'حذف',
    edit: 'تعديل',
    thePackage: 'الباقة',
    theCoupon: 'الكوبون',
    active: 'فعال',
    venders: 'البائعين',
    Restaurants: 'المطاعم',
    information: 'معلومات',
    payments: 'الدفعات',
    storeName: 'اسم المتجر',
    Notifications: 'الاشعارات',
    companyName: 'اسم الشركة',
    commercialRegistration: 'السجل التجاري',
    commercialRegistrationExpire: 'تاريخ انتهاء السجل التجاري',
    phone: 'رقم الموبايل',
    storeDescription: 'شرح عن المتجر',
    vendorInformation: 'معلومات عن المتجر',
    per: 'لكل',
    packageType:'نوع الباقة',
    subscriberInformation:'معلومات المشترك',
    paymentDetail:'تفاصيل الدفع',
    subscriptionDetail:'تفاصيل الاشتراك',
    subscriptionAt:'تم الاشتراك في',
    processId:'رقم العملية',
    amount:'المبلغ',
    date:'التاريخ',
    subscriptionEnds:'ينتهي الاشتراك في',
    close:'إغلاق',
    venderManagement: 'إدارة البائعين',
    createNewVender: 'إضافة بائع',
    searchVender: 'البحث عن البائع',
    status: 'الحالة',
    venderName: 'اسم المتجر',
    sellerName: 'اسم البائع',
    companyNname: 'الشركة',
    "Please select at least one package.": 'الرجاء تحديد باقة واحدة على الأقل.',
    products: 'المنتجات',
    package: 'الباقة',
    registerDate: 'تاريخ التسجيل',
    actions: 'الخيارات',
    users:'المستخدمين',
    user:'مستخدم',
    userManagement: 'إدارة المستخدمين',
    createNewUser: 'إضافة مستخدم',
    userName: 'اسم المستخدم',
    userType: 'نوع المستخدم',
    admin: 'مدير اللوحة',
    inactive: 'غير فعال',
    searchUser: 'البحث عن مستخدم',
    website: 'الموقع الالكتروني',
    numberOfSubscribers: 'عدد الاشتراكات',
    numberOfOrders: 'عدد الطلبات',
    searchSubscription: 'البحث عن الاشتراك',
    subscriptionManagement: 'إدارة الاشتراكات',
    createNewSubscription: 'إضافة اشتراك جديد',
    expiryDate: 'تاريخ الانتهاء',
    paymentMethod: 'طريقة الدفع',
    notRegisteredYet: 'لم يسجل بعد',
    deleteAccount: 'حذف الحساب',
    location: 'الموقع',
    view: 'معاينة',
    APPLE_PAY: 'آبل باي',
    "Try again!": 'اعادة المحاولة',
    SUCCESS: 'نجاح',
    CASH: 'نقداً',
    BANK_TRANSFER: 'حوالة بنكية',
    CREDIT_CARD: 'كرت ائتماني',
    Product: 'منتج',
    by: 'بواسطة',
    Subscribe: 'إشتراك',
    username: 'اسم المستخدم',
    passwordConfirmation: 'تأكيد كلمة المرور',
    avatar: 'الصورة',
    count: 'عدد',
    Seller: 'بائع',
    updateUser: 'تعديل المستخدم',
    roles: 'الادوار',
    NoActivePackage: 'لا توجد باقة مفعلة',
    copyright: 'جميع حقوق النشر محفوظة',
    aboutLinker: 'عن لنكر',
    contactUs: 'اتصل بنا',
    numberOfVenders: 'عدد البائعين',
    numberOfRestaurants: 'عدد المطاعم',
    numberOfProducts: 'عدد المنتجات',
    numberOfMeals: 'عدد الوجبات',
    ordersValue: 'قيمة الطلبات',
    numberOfCouponsUsed: 'عدد الكوبونات المستخدمة',
    numberOfPackages: 'عدد الباقات',
    byMonths: 'حسب الأشهر',
    newSellers: 'البائعين الجدد',
    newRestaurants: 'المطاعم الجديدة',
    newProducts: 'المنتجات الجديدة',
    newMeals: 'الوجبات الجديدة',
    meal: 'وجبة',
    subscriptionValue: 'قيمة الاشتراكات',
    bestSellers: 'أفضل البائعين',
    bestRestaurants: 'أفضل المطاعم',
    accordingToOrdersNumber: 'بحسب عدد الطلبات',
    accordingToTypesOfPackages: 'بحسب أنواع الباقات',
    orders: 'الطلبات',
    Language: 'اللغة',
    statistics: 'الإحصائيات',
    key: 'المفتاح',
    discount: 'خصم',
    continue: 'المتابعة',
    back: 'رجوع',
    subdomain: 'المجال الفرعي',
    pleaseChoosePaymentMethod: 'يرجى إختيار طريقة الدفع',
    applePay: 'آبل باي',
    creditCard: 'بطاقة ائتمانية',
    nameOnCard: 'اسم حامل البطاقة',
    cardNumber: 'رقم البطاقة',
    expirationDate: 'تاريخ انتهاء الصلاحية',
    addCouponCode: 'يرجى إضافة كود الكوبون',
    value: 'القيمة',
    theDiscount: 'الخصم',
    total: 'المبلغ الكلي',
    role :'نوع المستخدم',
    basic :'أساسي',
    branch :'فرعي',
    subject:"الموضوع",
    blogs: "المدونات",
    language: "اللغة",
    order: "الترتيب",
    seo_link: "لينك ",
    short_description: "مقدمة مختصرة",
    body: "الموضوع",
    arabic: "العربية",
    english: "الإنجليزية",
    title: "العنوان",
    message: "الرسالة",
    "blogs Management": "إدارة المدونات",
    "Create blog": "إنشاء مدونة",
    "update blog": "تعديل مدونة",
    contents:"المحتوى",
    "contents Management":"إدارة المحتوى",
    "Contact Management":"إدارة طلبات التواصل",
    "Submit":"إرسال",
    "Password reset successfully!":"تم إعادة تعيين كلمة المرور بنجاح!",
    "Please wait...": 'يرجى الانتظار...',
    "Old Password":"كلمة المرور القديمة",
    "Confirm Password":"تأكيد كلمة المرور",
    "Change password":"تغيير كلمة المرور",
    "New Password":"كلمة المرور الجديدة",
    "Password not correct":"كلمة المرور غير صحيحة",
    "Password updated successfully!":"تم تحديث كلمة المرور بنجاح!",
    "You cannot choose an earlier date": "لا يمكنك اختيار تاريخ سابق.",
    "Please select image": 'يرجى اختيار صورة',
    "No data found": "لا يوجد بيانات",
    "user saved successfully!": " تم الحفظ بنجاح",
    "Ok, got it!":"حسنًا ، موافق!",
    "Are you sure you want to delete?":"هل انت متاكد من حذف العنصر؟",
    "Yes, delete it!": "نعم",
    "No, cancel!": "لا",
    "coupon saved successfully!":"تم حفظ الكوبون بنجاح!",
    "Subscription package saved successfully!":"تم حفظ الباقة بنجاح!",
    "Required Field":"حقل مطلوب",
    "Verification link sent successfully please check your email!":"تم إرسال رابط التحقق بنجاح يرجى التحقق من بريدك الإلكتروني!",
    "Verification link already sent":".تم بالفعل إرسال رابط التحقق. يرجى الانتظار 5 دقائق قبل طلب رابط جديد ",
    "must be a valid email":"يجب ان يكون البريد الإلكتروني صالح",
    "phone must be a valid phone number":"يجب ان يكون رقم الهاتف صالح",
    "Passwords must match":"يجب تطابق كلمة المرور",
    "This admin is not active.": "هذا الحساب غير فعال.",
    "must be integer":"يجب أن يكون عدد صحيح",
    "must be grater than":"يجب أن يكون أكبر من",
    "must be less than":"يجب أن يكون أقل من",
    "must be":"يجب أن يكون",
    "number":"رقم",
    "Number":"العدد",
    "must be at least":"يجب ان يكون على الأقل",
    "must be at most":"يجب ان يكون على الأكثر",
    "The password must be at least 8 characters.":"يجب أن تتكون كلمة المرور من 8 أحرف على الأقل.",
    "characters":"أحرف",
    "token not valid.":"الرمز غير صالح",
    "Link has expired":"انتهت صلاحية الرابط",
    "Enter correct website!":"ادخل موقع صالح!",
    "Please enter website":"الموقع مطلوب",
    "string":"نص",
    "email already taken":"هذا الايميل موجود بالفعل",
    "Allowed file types":"أنواع الملفات المسموح بها",
    "Allowed file types2":"أنواع الملفات المسموح بها: png, jpg, jpeg.",
    "Invalid credentials":"البيانات غير صالحة",

    "No Notifications":"لا يوجد إشعارات",
    "invoice number":"رقم الفاتورة",
    "tax number":"الرقم الضريبي",
    "invoice date":"تاريخ الفاتورة",
    "time":"الوقت",
    "duration":"المدة",
    "price":"السعر",
    "tax":"الضريبة",
    "address":"العنوان",
    "The subscription is active until":"الإشتراك نشط حتى",
    "paid":"تم الدفع",
    "applications": "التطبيقات",
    "store Information":"معلومات المتجر",
    "print invoice":"طباعة الفاتورة",
    "company name":"اسم الشركة",
    "package type":"نوع الباقة",
    "market_id": "مُعرف السوق",
    "market_url": "رابط السوق",
    "gateway_type": "نوع البوابة",
    "is_linked": "حالة الربط",
    "linked": "تم الربط",
    "not_linked": "لم يتم الربط",
    "categories": "التصنيفات",
    "category":"التصنيف",
    "category saved successfully!":"تم حفظ التصنيف بنجاح!",
    "updateCategory": "تعديل التصنيف",
    "categoryManagement": "إدارة التصنيفات",
    "categoryName": "اسم التصنيف",
    "name ar": "الاسم بالعربي",
    "name en": "الاسم بالإنكليزي",
    "createNewCategory": "إضافة تصنيف",
    "property saved successfully!": "تم حفظ الخاصية بنجاح",
    "createNewProperty": "إضافة خاصية",
    "updateProperty": "تعديل الخاصية",
    "properties": "الخصائص",
    "Partners":"الشركاء",
    "Partners Management":"إدارة الشركاء",
    "Create Partner":"إنشاء شريك جديد",
    "Create content":"إنشاء محتوى",
    "update content":"تعديل المحتوى",
    "partner name":"اسم الشريك",
    "referral code":"رمز الإحالة",
    "create New Partner":"إنشاء شريك جديد",
    "Update Partner":"تحديث بيانات الشريك",
    "Company Name":"اسم الشركة",
    "wallet balance":"رصيد المحفظة",
    "referral group":"مجموعة الإحالة",
    "referral groups":"مجموعات الإحالة",
    "ShareReferralCode":"مشاركة الكود",
    "incomes_form_referral":"الدخل من الإحالة",
    "referral_code":"كود الإحالة",
    "PartnerIformation":"بيانات الشريك",
    "referralSellers":"بائعو الإحالة",
    "full_name":"الإسم بالكامل",
    "second_phone":"رقم الموبايل الثاني",
    "referral_due_months" :"عدد الأشهر المستحقة",
    "referral_consumed_months":"عدد الأشهر المستوفاة",
    "commercial_registration":"التسجيل التجاري",
    "tax_no":"الرقم الضريبي",
    "referral_percentage":"النسبة",
    "go-back":"عودة",


    
    "attribute_type": "نوع الخاصية",
    "linker_product_attribute": "خاصية منتج لينكر",
    "description ar":"الشرح بالعربي",
    "description en":"الشرح بالإنكليزي",
    "propertyManagement": "إدارة الخصائص",
    "saved successfully!": "تم الحفظ بنجاح",
    "categoryMapping": "ربط التصنيف",
    "main_category_id": "التصنيف الرئيسي",
    "not mapped": "غير مرتبط",
    "mapped": "مرتبط",
    "isMapped": "ارتباط التصنيف",
    "propertyMapping": "ربط الخاصية",
    "referral code groups": "مجموعات الإحالة",
    "referralCodeGroupsManagement": "إدارة مجموعات رمز الإحالة",
    "percentage": "النسبة المئوية",
    "numberOfMonths": "عدد الأشهر",
    "isDefaultForSellers": "افتراضي للبائع",
    "Yes": "نعم",
    "No": "لا",
    "createNewReferralCodeGroups": 'إضافة مجموعة',
    "createPayment": 'إضافة دفعة',
    "payment_no": 'رقم الدفعة',
    "payment_amount": 'قيمة الدفعة',
    "payment_date": 'تاريخ الدفعة',
    "payment_file": 'ملف الدفعة',
    "CACHE": 'نقدي',
    "BANK": 'حوالة',
    "USED_FOR_SUBSCRIPTION": 'اشتراك',
    "ReferralCodeGroups": "تحديث المجموعة",
    "isPropertyMapped": "ارتباط الخاصية",
    "mainProperty": "الخاصية الأساسية",
    "type": "النوع",
    subscriptionsPayment: 'دفعات الاشتراك',
    MARKET: 'متجر',
    RESTAURANT: 'مطعم',

    '':'',	



    short_description_ar:"المقدمة مختصرة بالعربي",
    short_description_en:"المقدمة مختصرة بالانكليزي",
    title_ar:"العنوان بالعربي",
    title_en:"العنوان بالانكليزي",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('lang') ?? 'ar',
  globalInjection: true,
  messages,
});

export default i18n;
