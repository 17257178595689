enum Actions {
  // action types
  ADMIN_VERIFY_AUTH = "adminVerifyAuth",
  ADMIN_LOGIN = "adminLogin",
  ADMIN_LOGOUT = "adminLogout",
  CHANGE_LANG = "changeLang",
  ADMIN_UPDATE_USER = "adminUpdateUser",
  ADMIN_FORGOT_PASSWORD = "adminForgotPassword",
  ADMIN_RESET_PASSWORD = "adminResetPassword",
  ADMIN_CHECK_RESET_TOKEN = "ADMIN_CHECK_RESET_TOKEN",
}

enum Mutations {
  // mutation types
  ADMIN_PURGE_AUTH = "adminLogOut",
  ADMIN_SET_AUTH = "adminSetAuth",
  ADMIN_SET_USER = "adminSetUser",
  ADMIN_SET_PASSWORD = "adminSetPassword",
  ADMIN_SET_ERROR = "adminSetError",
}

export { Actions, Mutations };
