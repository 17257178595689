import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { Actions, Mutations } from '../enums/AuthAdminEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import AlertService from '@/core/services/AlertService';
import axios from 'axios';

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentAdmin(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isAdminAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getAdminErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.ADMIN_SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.ADMIN_SET_AUTH](data) {
    this.isAuthenticated = true;
    this.user = data.result.admin;
    this.errors = {};
    JwtService.saveToken(data.result.token);
  }

  @Mutation
  [Mutations.ADMIN_SET_USER](data) {
    this.user = data.result.admin;
  }

  @Mutation
  [Mutations.ADMIN_SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.ADMIN_PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.ADMIN_LOGIN](credentials) {
    return ApiService.post('admin-panal/auth/login', credentials)
      .then(({ data }) => {
        if(data.result.admin.locale != localStorage.getItem('lang'))
        {
          localStorage.setItem(
            'lang',
            data.result.admin.locale ?? localStorage.getItem('lang') ?? 'ar'
          );
          window.location.reload();
        }
       
        this.context.commit(Mutations.ADMIN_SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.ADMIN_SET_ERROR, [response.data.message]);
      });
  }

  @Action
  [Actions.ADMIN_CHECK_RESET_TOKEN](payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/admin-panal/auth/check-reset-token', payload)
        .then((res) => {
          resolve(res);
        })
        .catch(({ response }) => {
          // console.log("response",response);
          // this.context.commit(Mutations.SET_ERROR, response.data.message);
          AlertService.errorText('Link has expired');
          reject(response);
        });
    });
  }

  @Action
  [Actions.ADMIN_LOGOUT]() {
    if (JwtService.getToken()) {
      return ApiService.query('admin-panal/auth/logout', {})
        .then(({ data }) => {
          this.context.commit(Mutations.ADMIN_PURGE_AUTH);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.ADMIN_PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.ADMIN_PURGE_AUTH);
    }
  }

  @Action
  [Actions.CHANGE_LANG](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(`admin-panal/setting/change-language`, {
        params: { locale: payload.lang },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.ADMIN_FORGOT_PASSWORD](payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/admin-panal/auth/forgot-password', payload)
        .then((res) => {
          resolve(res);
        })
        .catch(({ response }) => {
          AlertService.errorText(response.data.message);
          reject(response);
        });
    });
  }
  @Action
  [Actions.ADMIN_RESET_PASSWORD](payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/admin-panal/auth/reset-password', payload)
        .then((res) => {
          resolve(res);
        })
        .catch(({ response }) => {
          AlertService.errorText(response.data.message);
          reject(response);
        });
    });
  }
  @Action
  [Actions.ADMIN_VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.query('admin-panal/auth/me', {})
        .then(({ data }) => {
          this.context.commit(Mutations.ADMIN_SET_USER, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.ADMIN_SET_ERROR, response.data.errors);
          this.context.commit(Mutations.ADMIN_PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.ADMIN_PURGE_AUTH);
    }
  }
}
