import axios from 'axios';
import {AdminProfileAPIsConstants} from "../enums/AdminProfileEnums";

const AdminModulesModule = {
  namespaced: true,
  state: {
    data: [],
    loading: false,
    notifications: [],
    loadingItem: false,
    itemEditable: {},
    CustomerEditable: {},
    changePasswordLoading: false,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_DATA(state, payload) {
      state.data = payload.map((row) => {
        let arr = [];
        row.translations.map((lang) => {
          arr[lang.locale] = lang;
          row[lang.locale] = lang;
        });
        row['translations'] = arr;
        return row;
      });
    },
    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },
    SET_ITEM_EDITABLE(state, payload) {
      let arr = [];

      state.itemEditable = payload.itemEditable;
    },
    SET_CHANGE_PASSWORD_LOADING(state, payload) {
      state.changePasswordLoading = payload;
    },
    
  },
  actions: {
    getNotifications({ commit, state }, { apiPath }) {
      commit('SET_LOADING', true);
      
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}`)
          .then((response) => {
            state.notifications = response.data.result;
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
    getProfile({ commit, state }) {
      // if (!state.editableId) {
      //       //   return;
      //       // }
      commit('SET_LOADING_ITEM', true);
      return new Promise((resolve, reject) => {
      axios
        .get(`${AdminProfileAPIsConstants.API_PATH}/get-profile`)
        .then((res) => {
          commit('SET_LOADING_ITEM', false);
          commit('SET_ITEM_EDITABLE', {
            itemEditable: res.data.result?.admin,
          });
          // console.log('data',res.data);
          resolve(res);
        })
        .catch((error) => {
          commit('SET_LOADING_ITEM', false);
          // console.log(error);
          reject(error);
        });
      });
    },
    updateProfile({ commit, state }, { credentials }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${AdminProfileAPIsConstants.API_PATH}/update-profile`, credentials)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    changePassword({ commit, state }, { credentials }) {
      commit('SET_CHANGE_PASSWORD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .post(`${AdminProfileAPIsConstants.API_PATH}/change-password`, credentials)
          .then((response) => {
            commit('SET_CHANGE_PASSWORD_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_CHANGE_PASSWORD_LOADING', false);
            reject(error);
          });
      });
    },
  },
  getters: {

  },
};
export default AdminModulesModule;
