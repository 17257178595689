import Swal from 'sweetalert2/dist/sweetalert2.js';
import i18n from "@/core/plugins/i18n";

class AlertService {

  public static success(message) {
    const { t } = i18n.global;
    return new Promise((resolve) => {
      Swal.fire({
        text: t(message),
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: t('Ok, got it!'),
        customClass: {
          confirmButton: 'btn fw-bold btn-light-primary',
        },
      }).then(function (resp) {
        resolve(resp);
      });
    })

  }

  public static error(error) {
    const { t } = i18n.global;

    Swal.fire({
      text: t(error.response.data.message),
      icon: 'error',
      buttonsStyling: false,
      confirmButtonText: t('Try again!'),
      customClass: {
        confirmButton: 'btn fw-bold btn-light-danger',
      },
    });
  }

  public static errorText(message) {
    const { t } = i18n.global;

    return new Promise((resolve) => {
      Swal.fire({
        text: t(message),
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: t('Try again!'),
        customClass: {
          confirmButton: 'btn fw-bold btn-light-danger',
        },
      }).then(function (resp) {
        resolve(resp);
      });
    })
  }

  public static requiredError(message) {
    const { t } = i18n.global;
    const text = t(message)+' '+t('is required');
    Swal.fire({
      text: text,
      icon: 'error',
      buttonsStyling: false,
      confirmButtonText: t('Try again!'),
      customClass: {
        confirmButton: 'btn fw-bold btn-light-danger',
      },
    });
  }
}

export default AlertService;
