function addStyles(win, styles) {
    styles.forEach((style) => {
        let link = win.document.createElement("link");
        link.setAttribute("rel", "stylesheet");
        link.setAttribute("type", "text/css");
        link.setAttribute("href", style);
        win.document.getElementsByTagName("head")[0].appendChild(link);
    });
}

const VueHtmlToPaper = {
    install(app, options = {}) {
      // console.log('options',options);
      
        app.config.globalProperties.$htmlToPaper = (
            el,
            localOptions,
            cb = () => true
        ) => {
            let defaultName = "_blank",
                defaultSpecs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
                defaultReplace = true,
                defaultStyles = [];
            let {
                name = defaultName,
                specs = defaultSpecs,
                replace = defaultReplace,
                styles = defaultStyles
            } = options;

            // If has localOptions
            // TODO: improve logic
            if (localOptions) {
                if (localOptions.name) name = localOptions.name;
                if (localOptions.specs) specs = localOptions.specs;
                if (localOptions.replace) replace = localOptions.replace;
                if (localOptions.styles) styles = localOptions.styles;
            }

            specs = specs.length ? specs.join(",") : "";

            const element = window.document.getElementById(el);

            if (!element) {
                alert(`Element to print #${el} not found!`);
                return;
            }

            const url = "";
            const win = window.open(url, name, specs, replace);
            const lang = document.documentElement.getAttribute("lang");
            // console.log(document.body);
            
            const bodyClasses = 'document.body.classList.value';
            const pageLayoutClasses = 'document.getElementById("pageLayout").classList.value';

            win.document.write(`
          <html lang="${lang}">
            <head>
            <style>
            /******* begin::loader *******/
            .overlay{
                position: relative;
            }
            #overlayLayer{
            display: none;
            }
            .overlay .overlay-layer {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex !important;
                justify-content: center;
                align-items: center;
                background-color: rgba(0, 0, 0, 0.05);
                transition: all 0.3s ease;
                opacity: 1 !important;
                z-index: 1;
                background-color: white !important;
            }
            .spinner-border {
                display: inline-block;
                width: 2rem;
                height: 2rem;
                vertical-align: -0.125em;
                border: 0.185em solid currentColor;
                border-right-color: transparent;
                border-radius: 50%;
                -webkit-animation: 0.65s linear infinite spinner-border;
                animation: 0.65s linear infinite spinner-border;
            }
            
            /******* end::loader *******/
           
            .report-page{
                width: 100%;
                position: relative;
               }
               
            * {
              -webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
              color-adjust: exact !important;                /* Firefox 48 – 96 */
              print-color-adjust: exact !important;   
             -webkit-print-color-adjust: exact; 
            }
            table.dataTable thead tr * {
                background-color: inherit !important;
            }
            
            table.dataTable tbody tr * {
                background-color: inherit !important;
            }
            @page {
             size:210mm 297mm; 
                /*size: A4;*/
                /*margin: 0;*/
             }
             @media print {
                /* table.dataTable tbody tr:nth-child(even) {*/
                /*  -webkit-print-color-adjust: exact !important;   !* Chrome, Safari, Edge *!*/
                /*  color-adjust: exact !important;                !* Firefox 48 – 96 *!*/
                /*  print-color-adjust: exact !important; */
                /*  -webkit-print-color-adjust: exact; */
                /*}*/
                  html, body {
                    margin:0;
                    padding:0;
                  }
                  br.page-break {
                    display:block;
                    page-break-before:always;
                  }
                  table {
                    page-break-after:auto;
                  }
                  tr {
                    page-break-inside:avoid;
                    page-break-after:auto
                  }
                  td {
                    page-break-inside:avoid;
                    page-break-after:auto
                  }
                  thead {
                    display:table-header-group
                  }
                thead tr{
                    background: sandybrown;
                 }
                  tfoot {
                    display:table-footer-group
                  }
                  img {
                    display:block;
                  } 
                  .img-wrapper{
                   display: flex;
                   justify-content: center;
                  }
                }
</style>
              <title>${window.document.title}</title>
              <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css">
<!--              <link rel="stylesheet" type="text/css" href="https://dosoerp.com/assets/css/app.css">-->
              <link rel="stylesheet" type="text/css" href="/assets/css/app.css">
              <link rel="stylesheet" type="text/css" href="/assets/css/chunk-vendors.css">
            </head>
            <body id="kt_body" class="${bodyClasses} status_printing overlay">
                <div id="app" class="d-flex flex-column flex-root">
                    <div id="pageLayout" class="${pageLayoutClasses}">
                        <div style="width: 99% !important;">
                          ${element.innerHTML}
                        </div>
                    </div>
                </div>
                <div id="overlayLayer" class="overlay-layer">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </body>
            <script>
                // document.body.classList.add("overlay");
                // var element = document.getElementById("overlayLayer");
                // element.classList.add("overlay-layer");
                
                document.addEventListener("DOMContentLoaded", function(){
                    //dom is fully loaded, but maybe waiting on images & css files
                    // console.log("DOMContentLoaded");
                    // alert("DOMContentLoaded");
                    
                });
                // var readyStateCheckInterval = setInterval(function() {
                //     // console.log("document.readyState",document.readyState);
                //     if (document.readyState === "complete") {
                //         clearInterval(readyStateCheckInterval);
                //         console.log("loooood");
                //     }
                // }, 10);
                window.addEventListener("load", function() {
                     // alert("page loaded");
                    var element = document.getElementById("overlayLayer");
                    element.classList.remove("overlay-layer");
                    window.print();
                });
                // window.onload=function()
                //     {
                //         console.log("Complete loading");
                //     }
            </script>
          </html>
        `);

            addStyles(win, styles);

            setTimeout(() => {
                win.document.close();
                win.focus();
                // win.print();
                // win.close();
                // cb();
            }, 1000);

            function doPrint() {
                win.document.close();
                win.focus();
                win.print();
                win.close();
                cb();
            }

            return true;
        };
    }
};

export default VueHtmlToPaper;
