
import { defineComponent, onMounted, nextTick, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { initializeComponents } from '@/core/plugins/keenthemes';
import { Mutations } from '@/store/enums/StoreEnums';
import { useI18n } from 'vue-i18n';
import * as Yup from 'yup';
import { ListActions } from './store/enums/ListEnum';

export default defineComponent({
  name: 'app',
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const { t } = useI18n();
    const isFirstLoad = ref(true);

    Yup.setLocale({
      mixed: {
        required: t('Required Field'),
        notType: (params) => t('must be') + ' ' + t(params.type),
      },

      number: {
        integer: t('must be integer'),
        min: (params) => t('must be grater than') + ' ' + params.min,
        max: (params) => t('must be less than') + ' ' + params.max,
      },
      date: {
        min: (params) =>
          t('must be grater than') +
          ' ' +
          new Date(params.min).toJSON().slice(0, 10),
        max: (params) =>
          t('must be less than') +
          ' ' +
          new Date(params.max).toJSON().slice(0, 10),
      },
      array: {
        min: (params) =>
          t('must have at least') + ' ' + params.min + ' ' + t('items'),
        max: (params) =>
          t('must have at most') + ' ' + params.max + ' ' + t('items'),
      },
      string: {
        email: t('must be a valid email'),
        min: (params) =>
          t('must be at least') + ' ' + params.min + ' ' + t('characters'),
        max: (params) =>
          t('must be at most') + ' ' + params.max + ' ' + t('characters'),
        length: (params) =>
          t('must be') + ' ' + params.length + ' ' + t('characters'),
      },
    });

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      i18n.locale.value = localStorage.getItem('lang')
        ? (localStorage.getItem('lang') as string)
        : 'ar';

      const getHTMLTag = document.documentElement;
      getHTMLTag.setAttribute('lang', i18n.locale.value);
      getHTMLTag.setAttribute('dir', i18n.locale.value == 'ar' ? 'rtl' : 'ltr');
      getHTMLTag.style.setProperty(
        'direction',
        i18n.locale.value == 'ar' ? 'rtl' : 'ltr'
      );

      nextTick(() => {
        initializeComponents();
      });
    });

    const currentAdmin = computed(() => {
      return store.getters.currentAdmin;
    });

    watch(currentAdmin, () => {
      if (currentAdmin.value && isFirstLoad.value) {
        //load one time in whole system
        isFirstLoad.value = false;
        store.dispatch(ListActions.GET_LIST_BY_NAME, 'get-const-list');
      }
    });
  },
});
